import { ValidationObserver, ValidationProvider } from 'vee-validate'
import 'vue-slider-component/theme/default.css'
import get from 'lodash.get'

import LanguageSwitcher from '@/components/LanguageSwitcher/main'
import PopUp from '@/components/PopUp/main'
import globalHelper from '@/mixins/global'

import { languages } from '@/config/i18n'

export default {
  name: 'users-account',
  components: {
    LanguageSwitcher,
    PopUp,
    ValidationObserver,
    ValidationProvider
  },
  mixins: [globalHelper],
  data () {
    return {
      internal_username: null,
      internal_email: null,
      internal_locale: null,
      currentPassword: null,
      newPassword: null,
      cPassword: null,
      accountSuccess: false,
      loginSuccess: false,
      confirmationRequest: false,
      availableLocales: languages
    }
  },
  mounted () {
    this.$store.subscribe((mutation) => {
      switch (mutation.type) {
        case 'setLanguage':
          this.$refs.accountObserver && this.$refs.accountObserver.ctx && this.$refs.accountObserver.ctx.invalid && this.$refs.accountObserver.validate()
          this.$refs.loginObserver && this.$refs.loginObserver.ctx && this.$refs.loginObserver.ctx.invalid && this.$refs.loginObserver.validate()
          this.$refs.privacyObserver && this.$refs.privacyObserver.ctx && this.$refs.privacyObserver.ctx.invalid && this.$refs.privacyObserver.validate()
          break
      }
    })

    this.accountSuccess = false
    this.loginSuccess = false
  },
  beforeDestroy () {
  },

  computed: {
    username: {
      get () {
        return this.internal_username !== null ? this.internal_username
          : get(this.$store.state, 'user.username', null)
      },
      set (value) {
        this.internal_username = value
      }
    },
    email: {
      get () {
        return this.internal_email !== null ? this.internal_email
          : get(this.$store.state, 'user.email', null)
      },
      set (value) {
        this.internal_email = value
      }
    },
    locale: {
      get () {
        return this.internal_locale !== null ? this.internal_locale
          : get(this.$store.state, 'user.locale', null)
      },
      set (value) {
        this.internal_locale = value
      }
    }
  },
  methods: {
    /** the account update form */
    async updateAccount (event) {
      event.preventDefault()
      event.stopPropagation()

      this.accountSuccess = false

      const isValid = await this.$refs.accountObserver.validate()

      if (!isValid) {
        return
      }

      const body = {}
      if (this.internal_username) {
        body.username = this.internal_username
      }
      if (this.internal_email) {
        body.email = this.internal_email
      }
      if (this.internal_locale && this.internal_locale.id) {
        body.locale = this.internal_locale.id
      }

      this.$store.dispatch('user_update', body).then(
        (response) => {
          this.accountSuccess = true
          this.$events.$emit('user:updated', response)
        },
        (response) => {
          this.printErrors(response, this.$refs.accountObserver, 'account')
        }
      )
    },

    /** the password change form */
    async updatePassword (event) {
      event.preventDefault()
      event.stopPropagation()

      this.loginSuccess = false

      const isValid = await this.$refs.loginObserver.validate()

      if (!isValid) {
        return
      }

      const body = {
        current_password: this.currentPassword,
        new_password: this.newPassword
      }

      this.$store.dispatch('user_update', body).then(
        (response) => {
          this.loginSuccess = true
          // reset fields
          this.currentPassword = null
          this.newPassword = null
          this.cPassword = null// You should call it on the next frame
          requestAnimationFrame(() => {
            this.$refs.loginObserver.reset()
          })

          this.$events.$emit('user:updated', response)
        },
        (response) => {
          this.printErrors(response, this.$refs.loginObserver, 'login')
        }
      )
    },

    /** the account deletion form */
    deleteAccount (event) {
      event.preventDefault()
      event.stopPropagation()

      // show popup
      this.confirmationRequest = true
    },
    confirmDelete (event) {
      event.preventDefault()
      event.stopPropagation()

      this.$store.dispatch('user_delete').then(
        (response) => {
          // close popup
          this.confirmationRequest = false

          // logout user
          this.$store.dispatch('user_logout')

          // session expired
          this.$router.push({ name: 'UsersLogin', params: { action: 'login' } })
        },
        (response) => {
          // close popup
          this.confirmationRequest = false

          this.printErrors(response, this.$refs.privacyObserver, 'privacy')
        }
      )
    },
    cancelDelete (event) {
      event.preventDefault()
      event.stopPropagation()

      // close popup
      this.confirmationRequest = false
    }
  }
}
